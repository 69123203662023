@use '../../styles/breakpoints';

#dashboard {
  .app {
    display: grid;

    // @include breakpoints.create(xl) {
    //   grid-template-columns: 275px 1fr;
    // }
  }

  .container {
    max-width: unset;
    margin-block: 1.25rem;
  }
}