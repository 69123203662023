#casaLoader {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: .5rem;
  padding-top: 12rem;
  .linearProgress{
    width: 120px;
  }
}
